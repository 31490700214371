export var urlPath = "/bmpadmin";
export var USER_GUIDE_URL = "https://botzer.lntinfotech.com/userguide/bmp/";
export var BASE_URL = "https://botzer.lntinfotech.com/bmpadmin/";
export var BMP_FE = "https://botzer.lntinfotech.com/bmpadmin/";
export var BMP_BE = "https://botzer.lntinfotech.com/bmpapi/";
export var CHATBOT_URL = "https://botzer.lntinfotech.com/chatbot/chatpage?botId=";
export var CURRENT_SERVER = "https://botzer.lntinfotech.com/bmpapi/";
export var adminrole = "1";
export var developerrole = "2";
export var business_user = "4";
export var VOICE_API = "https://botzer.lntinfotech.com/lexapi/";
export var languageCode = "en";
export var liveAgentAPI = "https://botzer.lntinfotech.com/live-agentapi/";
export var domain_url = "https://botzer.lntinfotech.com/"; 
